<script>
import { mapState } from 'vuex';

export default {
  name: 'Admin',
  components: {},
  props: [],
  data() {
    return { projectNameDisplaySetting: [2, 1, 0] };
  },
  computed: {
    ...mapState(['selectedProject']),
    teamProjects() {
      return [...this.$store.getters.teamProjects];
    },
    projects() {
      return [...this.$store.getters.projectsViewable].sort(
        this.dynamicSort('projectId')
      );
    },
    projectNameDisplay() {
      const sort = this.sortProjectsSetting,
        mode = this.projectNameDisplaySetting;

      const displayProjectSetting = () => {
        const showAll = true;

        const modes = [showAll];

        return modes;
      };

      return [...this.projects]
        .filter(displayProjectSetting)
        .filter(p => p.projectId !== this.$route.params.projectId)
        .map(p => {
          const code = mode.includes(0) ? p.code : '',
            key = mode.includes(1) ? p.projectId : '',
            name = mode.includes(2) ? p.name : '';

          let displayName = [
            code ? code : '',
            key ? key : '',
            name ? name : '',
          ];

          if (mode.length === 0) {
            displayName = [p.name];
          }

          p.displayName = displayName.filter(p => p).join(' ');
          return p;
        })
        .sort((projectA, projectB) => {
          const { displayName: a } = projectA;
          const { displayName: b } = projectB;

          const direction = sort ? -1 : 1;

          return a === b ? 0 : a > b ? direction : -direction;
        });
    },
    isGrfn() {
      return this.$store.state.isGrfn;
    },
  },
  mounted() {},
  created() {},
  methods: {
    dynamicSort(property) {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder === -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      };
    },
    isTeamProject(project) {
      return this.teamProjects.some(p => p.projectId === project.projectId);
    },
  },
};
</script>

<template>
  <div>
    <v-card
      v-for="(project, p) in selectedProject"
      :key="p"
      outlined
      flat
      dense
      class="selectedProject mx-2"
      ><v-list-item>
        <v-list-item-content>
          <div class="text-overline">Selected Project</div>
          <v-list-item-title class="text-h5 project-name">
            <span class="">{{ project.name }}</span>
            <v-chip class="team-project" color="secondary" small
              >On Team</v-chip
            >
          </v-list-item-title>
        </v-list-item-content></v-list-item
      >
      <v-card-text class="pb-0"
        ><v-row
          ><v-col cols="2"
            ><v-text-field
              label="GRFN Code"
              :value="project.code"
              hint="Two digits and a letter" /></v-col
          ><v-col cols="2"
            ><v-text-field
              :value="project.key"
              label="Key"
              hint="Uppercase
            letters - Project Standard" /></v-col
          ><v-col cols="8"
            ><v-text-field :value="project.name" label="Project Name" hint=""
          /></v-col>
          <v-col>
            <div class="project-switch mt-0 pt-0 d-flex flex-wrap">
              <v-switch
                v-model="project.archived"
                color="secondary"
                dense
                label="Archived"
              /><v-switch
                v-model="project.live"
                dense
                color="secondary"
                label="Live"
              />
            </div>
            <div class="project-switch mt-0 pt-0 d-flex flex-wrap">
              <v-switch
                v-model="project.hasInsights"
                dense
                color="secondary"
                label="Insights"
              /><v-switch
                v-model="project.hasIssues"
                dense
                color="secondary"
                label="Issues"
              /><v-switch
                v-model="project.hasMonitoring"
                dense
                color="secondary"
                disabled
                label="Monitoring"
              /><v-switch
                v-model="project.hasModels"
                dense
                disabled
                color="secondary"
                label="Models"
              /></div
          ></v-col>
          <v-col
            v-if="teamProjects.some(p => p.projectId === project.key)"
            cols="2"
            class="team-flag" /></v-row
        ><v-row class="my-0"
          ><v-col class="ma-0" cols="12"
            ><v-text-field
              v-if="project.hasInsights"
              label="Insights URL"
              hint="The URL retrieved from Data Studio embed workbook option."
              flat
              :value="project.insights" />
            <v-text-field
              v-if="project.hasIssues"
              label="Issue Register"
              hint="The BigQuery Issue List View"
              flat
              :value="project.issueList" /></v-col
        ></v-row> </v-card-text
      ><v-card-actions class="py-0"
        ><v-btn text color="secondary">Team</v-btn
        ><v-btn color="secondary" text>Directories</v-btn></v-card-actions
      >
    </v-card>
    <v-card flat
      ><v-card-title class="d-flex justify-space-between"
        ><div class="text-overline">Remaining Projects</div>
        <v-btn text disabled x-small class="text-overline" color="secondary"
          >Add Project</v-btn
        ></v-card-title
      >
      <v-card-text>
        <ul class="project-list">
          <li v-for="(project, p) in projectNameDisplay" :key="p">
            <v-row align="center">
              <v-col cols="1" class="project-label code">{{
                project.code
              }}</v-col
              ><v-col cols="1" class="project-label key">{{
                project.projectId
              }}</v-col
              ><v-col cols="4" class="project-label project-name">{{
                project.name
              }}</v-col>
              <v-col cols="2" class="project-switch"
                ><v-switch
                  :value="project.archived"
                  color="secondary"
                  dense
                  label="Archived" /></v-col
              ><v-col class="project-switch" cols="2"
                ><v-switch
                  dense
                  color="secondary"
                  :value="project.live"
                  label="Live"
              /></v-col>
              <v-col
                v-if="teamProjects.some(p => p.projectId === project.projectId)"
                cols="2"
                class="team-flag"
                ><v-chip class="team-project" color="secondary" small
                  >On Team</v-chip
                ></v-col
              ></v-row
            >
          </li>
        </ul></v-card-text
      ></v-card
    >
  </div>
</template>

<style lang="scss" scoped>
.project-name {
  span {
    margin-right: 1ex;
  }
}
.selectedProject {
  .project-switch {
    padding-top: 3px;
    #{$v-deep} .v-label {
      font-size: 0.75rem;
    }
    .v-input {
      margin-top: 0;
      margin-right: 2rem;
    }
  }
}
.project-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    white-space: nowrap;
    border-bottom: 1px solid #ccc;

    &:first-child {
      border-top: 1px solid #ccc;
    }
    .project-switch {
      padding-top: 3px;
      #{$v-deep} .v-label {
        font-size: 0.75rem;
      }
    }
    .project-label {
      margin-top: 4px;
      padding-top: 0;
      &.code {
        color: #666;
      }
      &.key {
        color: #999;
        margin-left: -1em;
        margin-right: 1ex;
      }
      &.project-name {
        color: #333;
      }
    }
    .team-flag {
      padding-top: 5px;
    }
  }
}
</style>
